import LockableComponent from "../Lockable/LockableComponent";
import {TimelineMax} from "gsap";

class OrdreComponent extends LockableComponent {
  init() {
    this.$btnChecker = this.element.find('.btn-test');
    this.$resetBtn = this.element.find('.btn-reset');

    super.init();

    this.selections = {
      items: [],
      owner: null,
      droptarget: null
    };

    this.related = null;
    this.targets = [];
    this.items = [];
    this.debug = false;

    this.checkDnD();
    this.initDnd();
    this.registerDnDEvents();

    this.lockBtn(this.$btnChecker);
    this.lockBtn(this.$resetBtn);
    this.registerAnswerCheckTrigger();
    this.registerResetTrigger();
  }

  checkDnD() {
    if
    (
      !document.querySelectorAll
      ||
      !('draggable' in document.createElement('span'))
      ||
      window.opera
    ) {
      this.unsupportedDnd();
    }
  }

  unsupportedDnd() {
    let EventManager = window.EventManager || $(document);
    EventManager.trigger('notification', {
      type: 'error',
      msg: 'Votre navigateur ne supporte pas les interactions drag and drop',
      dest: this.element.find('.content'),
      focus: true
    });
  }

  initDnd() {
    //get the collection of draggable targets and add their draggable attribute
    this.targets = document.querySelectorAll('[data-draggable="target"]');
    let len = this.targets.length,
      i = 0;
    for (this.targets, len, i; i < len; i++) {
      this.targets[i].setAttribute('aria-dropeffect', 'none');
    }

    //get the collection of draggable items and add their draggable attributes
    this.items = document.querySelectorAll('[data-draggable="item"]');
    let len2 = this.items.length,
      j = 0;
    for (this.items,
           len2,
           j;
         j < len2;
         j++
    ) {
      this.items[j].setAttribute('draggable', 'true');
      this.items[j].setAttribute('aria-grabbed', 'false');
      this.items[j].setAttribute('tabindex', '0');
    }

  }

  registerDnDEvents() {
    this.element[0].addEventListener('mousedown', (e) => { this.handleMouseDown(e); });
    this.element[0].addEventListener('mouseup', (e) => { this.handleMouseUp(e); });
    this.element[0].addEventListener('dragstart', (e) => { this.handleDragStart(e); });
    this.element[0].addEventListener('dragenter', (e) => { this.handleDragEnter(e); });
    this.element[0].addEventListener('dragleave', (e) => { setTimeout(() => this.handleDragLeave(e)); });
    this.element[0].addEventListener('dragover', (e) => { this.handleDragOver(e); });
    this.element[0].addEventListener('dragend', (e) => {
      this.handleDragEnd(e);
      this.checkUnlockAnswerTrigger()
      this.unlockResetBtn();
    });
  }

  handleMouseDown(e) {
    if (this.debug) { console.log('handleMouseDown'); }
    //if the element is a draggable item
    if (e.target.getAttribute('draggable')) {
      //clear dropeffect from the target containers
      this.clearDropeffects();

      //if the multiple selection modifier is not pressed
      //and the item's grabbed state is currently false
      if
      (
        !this.hasModifier(e)
        &&
        e.target.getAttribute('aria-grabbed') === 'false'
      ) {
        //clear all existing this.selections
        this.clearSelections();

        //then add this new selection
        this.addSelection(e.target);
      }
    }

      //else [if the element is anything else]
    //and the selection modifier is not pressed
    else if (!this.hasModifier(e)) {
      //clear dropeffect from the target containers
      this.clearDropeffects();

      //clear all existing this.selections
      this.clearSelections();
    }

    //else [if the element is anything else and the modifier is pressed]
    else {
      //clear dropeffect from the target containers
      this.clearDropeffects();
    }
  }

  handleMouseUp(e) {
    if (this.debug) { console.log('handleMouseUp'); }
//if the element is a draggable item
    //and the multipler selection modifier is pressed
    if (e.target.getAttribute('draggable') && this.hasModifier(e)) {
      //if the item's grabbed state is currently true
      if (e.target.getAttribute('aria-grabbed') === 'true') {
        //unselect this item
        this.removeSelection(e.target);

        //if that was the only selected item
        //then reset the owner container reference
        if (!this.selections.items.length) {
          this.selections.owner = null;
        }
      }

      //else [if the item's grabbed state is false]
      else {
        //add this additional selection
        this.addSelection(e.target);
      }
    }
  }

  handleDragStart(e) {
    if (this.debug) { console.log('handleDragStart'); }
//if the element's parent is not the owner, then block this event
    if (this.selections.owner !== e.target.parentNode) {
      e.preventDefault();
      return;
    }

    //[else] if the multiple selection modifier is pressed
    //and the item's grabbed state is currently false
    if
    (
      this.hasModifier(e)
      &&
      e.target.getAttribute('aria-grabbed') === 'false'
    ) {
      //add this additional selection
      this.addSelection(e.target);
    }

    //we don't need the transfer data, but we have to define something
    //otherwise the drop action won't work at all in firefox
    //most browsers support the proper mime-type syntax, eg. "text/plain"
    //but we have to use this incorrect syntax for the benefit of IE10+
    e.dataTransfer.setData('text', '');

    //apply dropeffect to the target containers
    this.addDropeffects();
  }

  handleDragEnter(e) {
    if (this.debug) { console.log('handleDragEnter'); }
    this.related = e.target;
  }

  handleDragLeave() {
    if (this.debug) { console.log('handleDragLeave'); }
//get a drop target reference from the relatedTarget
    let droptarget = this.getContainer(this.related);

    //if the target is the owner then it's not a valid drop target
    if (droptarget === this.selections.owner) {
      droptarget = null;
    }

    //if the drop target is different from the last stored reference
    //(or we have one of those references but not the other one)
    if (droptarget !== this.selections.droptarget) {
      //if we have a saved reference, clear its existing dragover class
      if (this.selections.droptarget) {
        this.selections.droptarget.className =
          this.selections.droptarget.className.replace(/ dragover/g, '');
      }

      //apply the dragover class to the new drop target reference
      if (droptarget) {
        droptarget.className += ' dragover';
      }

      //then save that reference for next time
      this.selections.droptarget = droptarget;
    }
  }

  handleDragOver(e) {
    //if we have any selected items, allow them to be dragged
    if (this.selections.items.length) {
      e.preventDefault();
    }
  }

  handleDragEnd(e) {
    if (this.debug) { console.log('handleDragEnd'); }
    //if we have a valid drop target reference
    //(which implies that we have some selected items)
    if (this.selections.droptarget) {
      //append the selected items to the end of the target container
      for (let len = this.selections.items.length, i = 0; i < len; i++) {
        this.selections.droptarget.appendChild(this.selections.items[i]);
      }

      //prevent default to allow the action
      e.preventDefault();
    }

    //if we have any selected items
    if (this.selections.items.length) {
      //clear dropeffect from the target containers
      this.clearDropeffects();

      //if we have a valid drop target reference
      if (this.selections.droptarget) {
        //reset the this.selections array
        this.clearSelections();

        //reset the target's dragover class
        this.selections.droptarget.className =
          this.selections.droptarget.className.replace(/ dragover/g, '');

        //reset the target reference
        this.selections.droptarget = null;
      }
    }
  }

  //  for selecting an item
  addSelection(item) {
    //if the owner reference is still null, set it to this item's parent
    //so that further selection is only allowed within the same container
    if (!this.selections.owner) {
      this.selections.owner = item.parentNode;
    }

      //or if that's already happened then compare it with this item's parent
    //and if they're not the same container, return to prevent selection
    else if (this.selections.owner !== item.parentNode) {
      return;
    }

    //set this item's grabbed state
    item.setAttribute('aria-grabbed', 'true');

    //add it to the items array
    this.selections.items.push(item);
  }

  //  for unselecting an item
  removeSelection(item) {
    //reset this item's grabbed state
    item.setAttribute('aria-grabbed', 'false');

    //then find and remove this item from the existing items array
    for (let len = this.selections.items.length, i = 0; i < len; i++) {
      if (this.selections.items[i] === item) {
        this.selections.items.splice(i, 1);
        break;
      }
    }
  }

  //  for resetting all this.selections
  clearSelections() {
    //if we have any selected items
    if (this.selections.items.length) {
      //reset the owner reference
      this.selections.owner = null;

      //reset the grabbed state on every selected item
      for (let len = this.selections.items.length, i = 0; i < len; i++) {
        this.selections.items[i].setAttribute('aria-grabbed', 'false');
      }

      //then reset the items array
      this.selections.items = [];
    }
  }

  //shorctut   for testing whether a selection modifier is pressed
  hasModifier(e) {
    return (e.ctrlKey || e.metaKey || e.shiftKey);
  }

  //  for applying dropeffect to the target containers
  addDropeffects() {
    //apply aria-dropeffect and tabindex to all targets apart from the owner

    for (let len = this.targets.length, i = 0; i < len; i++) {
      if
      (
        this.targets[i] !== this.selections.owner
        &&
        this.targets[i].getAttribute('aria-dropeffect') === 'none'
      ) {
        this.targets[i].setAttribute('aria-dropeffect', 'move');
        this.targets[i].setAttribute('tabindex', '0');
      }
    }

    //remove aria-grabbed and tabindex from all items inside those containers
    for (let len = this.items.length, i = 0; i < len; i++) {
      if
      (
        this.items[i].parentNode !== this.selections.owner
        &&
        this.items[i].getAttribute('aria-grabbed')
      ) {
        this.items[i].removeAttribute('aria-grabbed');
        this.items[i].removeAttribute('tabindex');
      }
    }
  }

  //  for removing dropeffect from the target containers
  clearDropeffects() {
    //if we have any selected items
    if (this.selections.items.length) {
      //reset aria-dropeffect and remove tabindex from all targets
      for (let len = this.targets.length, i = 0; i < len; i++) {
        if (this.targets[i].getAttribute('aria-dropeffect') !== 'none') {
          this.targets[i].setAttribute('aria-dropeffect', 'none');
          this.targets[i].removeAttribute('tabindex');
        }
      }

      //restore aria-grabbed and tabindex to all selectable items
      //without changing the grabbed value of any existing selected items
      for (let len = this.items.length, i = 0; i < len; i++) {
        if (!this.items[i].getAttribute('aria-grabbed')) {
          this.items[i].setAttribute('aria-grabbed', 'false');
          this.items[i].setAttribute('tabindex', '0');
        } else if (this.items[i].getAttribute('aria-grabbed') === 'true') {
          this.items[i].setAttribute('tabindex', '0');
        }
      }
    }
  }

  //shortcut   for identifying an event element's target container
  getContainer(element) {
    do {
      if (element.nodeType === 1 && element.getAttribute('aria-dropeffect')) {
        return element;
      }
    }
    while (element === element.parentNode);

    return null;
  }


  checkUnlockAnswerTrigger() {
    // Quand on a placé tous les elements, on débloque le bouton
    if (this.areAllAnswersCompleted()) {
      this.unlockBtn(this.$btnChecker);
    }
  }

  areAllAnswersCompleted() {
    let $placeHolders = this.element.find('.ordre-placeholder');
    let placeholdersCount = $placeHolders.length;
    let activeCount = 0;
    $placeHolders.each((i, placeholder) => {
      let childplLength = $(placeholder).find('.ordre-item').length;
      if (childplLength > 0) {
        activeCount++;
      }
    });
    return placeholdersCount === activeCount;
  }

  registerAnswerCheckTrigger() {
    this.$btnChecker.on('click', () => {
      this.verifyAnswers();
      this.$btnChecker.fadeOut();
      this.$resetBtn.fadeOut();
    });
  }

  verifyAnswers() {
    let tl = new TimelineMax({
      paused: true,
      onComplete: this.unlock.bind(this)
    });
    let subTl = new TimelineMax();
    let $answerList = this.element.find('.ordre-placeholder');
    let wrongAnswers = [];
    $answerList.each((i, item) => {
      let $placeHolder = $(item);
      let isCorrect = this.verifyAnswer($placeHolder, i);

      if (isCorrect) {
        tl.to($placeHolder, .1, {className: '+=ordre-placeholder success'});
      } else {
        let $item = $placeHolder.find('.ordre-item');
        tl.to($placeHolder, .1, {className: '+=ordre-placeholder error'});
        tl.fromTo($item, 0.01, {x: -2}, {x: 2, clearProps: "x", repeat: 20});
        subTl.to($item, .2, {x: 10, autoAlpha: 0});
        wrongAnswers.push($item);
      }
    });
    tl.add(subTl, '+=.7');

    let wrongAnswersCorrectionTl = this.getWrongAnswersCorrectionTl(wrongAnswers);
    tl.add(wrongAnswersCorrectionTl, '+=.3');

    tl.play();
  }

  verifyAnswer($placeHolder, index) {
    let order = $placeHolder.data('index');
    let $answerElt = $placeHolder.find('.ordre-item');
    let givenAnswer = $answerElt.data('ordre');

    return (order === givenAnswer);
  }

  getWrongAnswersCorrectionTl(wrongAnswers) {
    let subTl = new TimelineMax();
    if (wrongAnswers.length) {
      for (let i in wrongAnswers) {
        let $item = wrongAnswers[i];
        let $placeHolder = $item.parent();
        subTl.to($placeHolder, 0.01, {className: 'ordre-placeholder success'}, 0);
        let ordre = $item.data('ordre');
        subTl.add(() => {
          $('.ordre-placeholder[data-index="' + ordre + '"]').append($item);
        }, 0);
        subTl.to($item, .2, {x: 0, autoAlpha: 1}, (ordre * .2));
      }
    }
    return subTl;
  }

  registerResetTrigger() {
    this.element.find('.btn-reset').on('click', (e) => {
      e.preventDefault();

      let $items = this.element.find('.ordre-item'),
      numOfItems = $items.length,
        $resetDest = this.element.find('.propositions-wrap')

      for (let i = 0; i <= numOfItems; i++) {
        console.log(i,$items.eq(i));
        $items.eq(i).appendTo($resetDest);
      }

      this.lockBtn(this.$btnChecker);
      this.lockBtn(this.$resetBtn);
    });
  }

  unlockResetBtn(){
    let selectedLength = this.element.find('.ordre-placeholder .ordre-item').length;
    if(selectedLength>0) {
      this.unlockBtn(this.$resetBtn);
    } else {
      this.lockBtn(this.$resetBtn);
    }
  }

}

//---------------------------------------------------------------------------------------------------------------------------------------------------------------

var DragDropTouch;
(function (DragDropTouch_1) {
  'use strict';
  /**
   * Object used to hold the data that is being dragged during drag and drop operations.
   *
   * It may hold one or more data items of different types. For more information about
   * drag and drop operations and data transfer objects, see
   * <a href="https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer">HTML Drag and Drop API</a>.
   *
   * This object is created automatically by the @see:DragDropTouch singleton and is
   * accessible through the @see:dataTransfer property of all drag events.
   */
  var DataTransfer = (function () {
    function DataTransfer() {
      this._dropEffect = 'move';
      this._effectAllowed = 'all';
      this._data = {};
    }

    Object.defineProperty(DataTransfer.prototype, "dropEffect", {
      /**
       * Gets or sets the type of drag-and-drop operation currently selected.
       * The value must be 'none',  'copy',  'link', or 'move'.
       */
      get: function () {
        return this._dropEffect;
      },
      set: function (value) {
        this._dropEffect = value;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(DataTransfer.prototype, "effectAllowed", {
      /**
       * Gets or sets the types of operations that are possible.
       * Must be one of 'none', 'copy', 'copyLink', 'copyMove', 'link',
       * 'linkMove', 'move', 'all' or 'uninitialized'.
       */
      get: function () {
        return this._effectAllowed;
      },
      set: function (value) {
        this._effectAllowed = value;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(DataTransfer.prototype, "types", {
      /**
       * Gets an array of strings giving the formats that were set in the @see:dragstart event.
       */
      get: function () {
        return Object.keys(this._data);
      },
      enumerable: true,
      configurable: true
    });
    /**
     * Removes the data associated with a given type.
     *
     * The type argument is optional. If the type is empty or not specified, the data
     * associated with all types is removed. If data for the specified type does not exist,
     * or the data transfer contains no data, this method will have no effect.
     *
     * @param type Type of data to remove.
     */
    DataTransfer.prototype.clearData = function (type) {
      if (type != null) {
        delete this._data[type];
      } else {
        this._data = null;
      }
    };
    /**
     * Retrieves the data for a given type, or an empty string if data for that type does
     * not exist or the data transfer contains no data.
     *
     * @param type Type of data to retrieve.
     */
    DataTransfer.prototype.getData = function (type) {
      return this._data[type] || '';
    };
    /**
     * Set the data for a given type.
     *
     * For a list of recommended drag types, please see
     * https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Recommended_Drag_Types.
     *
     * @param type Type of data to add.
     * @param value Data to add.
     */
    DataTransfer.prototype.setData = function (type, value) {
      this._data[type] = value;
    };
    /**
     * Set the image to be used for dragging if a custom one is desired.
     *
     * @param img An image element to use as the drag feedback image.
     * @param offsetX The horizontal offset within the image.
     * @param offsetY The vertical offset within the image.
     */
    DataTransfer.prototype.setDragImage = function (img, offsetX, offsetY) {
      var ddt = DragDropTouch._instance;
      ddt._imgCustom = img;
      ddt._imgOffset = {x: offsetX, y: offsetY};
    };
    return DataTransfer;
  }());
  DragDropTouch_1.DataTransfer = DataTransfer;
  /**
   * Defines a class that adds support for touch-based HTML5 drag/drop operations.
   *
   * The @see:DragDropTouch class listens to touch events and raises the
   * appropriate HTML5 drag/drop events as if the events had been caused
   * by mouse actions.
   *
   * The purpose of this class is to enable using existing, standard HTML5
   * drag/drop code on mobile devices running IOS or Android.
   *
   * To use, include the DragDropTouch.js file on the page. The class will
   * automatically start monitoring touch events and will raise the HTML5
   * drag drop events (dragstart, dragenter, dragleave, drop, dragend) which
   * should be handled by the application.
   *
   * For details and examples on HTML drag and drop, see
   * https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Drag_operations.
   */
  var DragDropTouch = (function () {
    /**
     * Initializes the single instance of the @see:DragDropTouch class.
     */
    function DragDropTouch() {
      this._lastClick = 0;
      // enforce singleton pattern
      if (DragDropTouch._instance) {
        throw 'DragDropTouch instance already created.';
      }
      // detect passive event support
      // https://github.com/Modernizr/Modernizr/issues/1894
      var supportsPassive = false;
      document.addEventListener('test', function () { }, {
        get passive() {
          supportsPassive = true;
          return true;
        }
      });
      // listen to touch events
      if ('ontouchstart' in document) {
        var d = document, ts = this._touchstart.bind(this), tm = this._touchmove.bind(this), te = this._touchend.bind(this), opt = supportsPassive ? {passive: false, capture: false} : false;
        d.addEventListener('touchstart', ts, opt);
        d.addEventListener('touchmove', tm, opt);
        d.addEventListener('touchend', te);
        d.addEventListener('touchcancel', te);
      }
    }

    /**
     * Gets a reference to the @see:DragDropTouch singleton.
     */
    DragDropTouch.getInstance = function () {
      return DragDropTouch._instance;
    };
    // ** event handlers
    DragDropTouch.prototype._touchstart = function (e) {
      var _this = this;
      if (this._shouldHandle(e)) {
        // raise double-click and prevent zooming
        if (Date.now() - this._lastClick < DragDropTouch._DBLCLICK) {
          if (this._dispatchEvent(e, 'dblclick', e.target)) {
            e.preventDefault();
            this._reset();
            return;
          }
        }
        // clear all variables
        this._reset();
        // get nearest draggable element
        var src = this._closestDraggable(e.target);
        if (src) {
          // give caller a chance to handle the hover/move events
          if (!this._dispatchEvent(e, 'mousemove', e.target) &&
            !this._dispatchEvent(e, 'mousedown', e.target)) {
            // get ready to start dragging
            this._dragSource = src;
            this._ptDown = this._getPoint(e);
            this._lastTouch = e;
            e.preventDefault();
            // show context menu if the user hasn't started dragging after a while
            setTimeout(function () {
              if (_this._dragSource == src && _this._img == null) {
                if (_this._dispatchEvent(e, 'contextmenu', src)) {
                  _this._reset();
                }
              }
            }, DragDropTouch._CTXMENU);
            if (DragDropTouch._ISPRESSHOLDMODE) {
              this._pressHoldInterval = setTimeout(function () {
                _this._isDragEnabled = true;
                _this._touchmove(e);
              }, DragDropTouch._PRESSHOLDAWAIT);
            }
          }
        }
      }
    };
    DragDropTouch.prototype._touchmove = function (e) {
      if (this._shouldCancelPressHoldMove(e)) {
        this._reset();
        return;
      }
      if (this._shouldHandleMove(e) || this._shouldHandlePressHoldMove(e)) {
        // see if target wants to handle move
        var target = this._getTarget(e);
        if (this._dispatchEvent(e, 'mousemove', target)) {
          this._lastTouch = e;
          e.preventDefault();
          return;
        }
        // start dragging
        if (this._dragSource && !this._img && this._shouldStartDragging(e)) {
          this._dispatchEvent(e, 'dragstart', this._dragSource);
          this._createImage(e);
          this._dispatchEvent(e, 'dragenter', target);
        }
        // continue dragging
        if (this._img) {
          this._lastTouch = e;
          e.preventDefault(); // prevent scrolling
          if (target != this._lastTarget) {
            this._dispatchEvent(this._lastTouch, 'dragleave', this._lastTarget);
            this._dispatchEvent(e, 'dragenter', target);
            this._lastTarget = target;
          }
          this._moveImage(e);
          this._isDropZone = this._dispatchEvent(e, 'dragover', target);
        }
      }
    };
    DragDropTouch.prototype._touchend = function (e) {
      if (this._shouldHandle(e)) {
        // see if target wants to handle up
        if (this._dispatchEvent(this._lastTouch, 'mouseup', e.target)) {
          e.preventDefault();
          return;
        }
        // user clicked the element but didn't drag, so clear the source and simulate a click
        if (!this._img) {
          this._dragSource = null;
          this._dispatchEvent(this._lastTouch, 'click', e.target);
          this._lastClick = Date.now();
        }
        // finish dragging
        this._destroyImage();
        if (this._dragSource) {
          if (e.type.indexOf('cancel') < 0 && this._isDropZone) {
            this._dispatchEvent(this._lastTouch, 'drop', this._lastTarget);
          }
          this._dispatchEvent(this._lastTouch, 'dragend', this._dragSource);
          this._reset();
        }
      }
    };
    // ** utilities
    // ignore events that have been handled or that involve more than one touch
    DragDropTouch.prototype._shouldHandle = function (e) {
      return e &&
        !e.defaultPrevented &&
        e.touches && e.touches.length < 2;
    };

    // use regular condition outside of press & hold mode
    DragDropTouch.prototype._shouldHandleMove = function (e) {
      return !DragDropTouch._ISPRESSHOLDMODE && this._shouldHandle(e);
    };

    // allow to handle moves that involve many touches for press & hold
    DragDropTouch.prototype._shouldHandlePressHoldMove = function (e) {
      return DragDropTouch._ISPRESSHOLDMODE &&
        this._isDragEnabled && e && e.touches && e.touches.length;
    };

    // reset data if user drags without pressing & holding
    DragDropTouch.prototype._shouldCancelPressHoldMove = function (e) {
      return DragDropTouch._ISPRESSHOLDMODE && !this._isDragEnabled &&
        this._getDelta(e) > DragDropTouch._PRESSHOLDMARGIN;
    };

    // start dragging when specified delta is detected
    DragDropTouch.prototype._shouldStartDragging = function (e) {
      var delta = this._getDelta(e);
      return delta > DragDropTouch._THRESHOLD ||
        (DragDropTouch._ISPRESSHOLDMODE && delta >= DragDropTouch._PRESSHOLDTHRESHOLD);
    }

    // clear all members
    DragDropTouch.prototype._reset = function () {
      this._destroyImage();
      this._dragSource = null;
      this._lastTouch = null;
      this._lastTarget = null;
      this._ptDown = null;
      this._isDragEnabled = false;
      this._isDropZone = false;
      this._dataTransfer = new DataTransfer();
      clearInterval(this._pressHoldInterval);
    };
    // get point for a touch event
    DragDropTouch.prototype._getPoint = function (e, page) {
      if (e && e.touches) {
        e = e.touches[0];
      }
      return {x: page ? e.pageX : e.clientX, y: page ? e.pageY : e.clientY};
    };
    // get distance between the current touch event and the first one
    DragDropTouch.prototype._getDelta = function (e) {
      if (DragDropTouch._ISPRESSHOLDMODE && !this._ptDown) { return 0; }
      var p = this._getPoint(e);
      return Math.abs(p.x - this._ptDown.x) + Math.abs(p.y - this._ptDown.y);
    };
    // get the element at a given touch event
    DragDropTouch.prototype._getTarget = function (e) {
      var pt = this._getPoint(e), el = document.elementFromPoint(pt.x, pt.y);
      while (el && getComputedStyle(el).pointerEvents == 'none') {
        el = el.parentElement;
      }
      return el;
    };
    // create drag image from source element
    DragDropTouch.prototype._createImage = function (e) {
      // just in case...
      if (this._img) {
        this._destroyImage();
      }
      // create drag image from custom element or drag source
      var src = this._imgCustom || this._dragSource;
      this._img = src.cloneNode(true);
      this._copyStyle(src, this._img);
      this._img.style.top = this._img.style.left = '-9999px';
      // if creating from drag source, apply offset and opacity
      if (!this._imgCustom) {
        var rc = src.getBoundingClientRect(), pt = this._getPoint(e);
        this._imgOffset = {x: pt.x - rc.left, y: pt.y - rc.top};
        this._img.style.opacity = DragDropTouch._OPACITY.toString();
      }
      // add image to document
      this._moveImage(e);
      document.body.appendChild(this._img);
    };
    // dispose of drag image element
    DragDropTouch.prototype._destroyImage = function () {
      if (this._img && this._img.parentElement) {
        this._img.parentElement.removeChild(this._img);
      }
      this._img = null;
      this._imgCustom = null;
    };
    // move the drag image element
    DragDropTouch.prototype._moveImage = function (e) {
      var _this = this;
      requestAnimationFrame(function () {
        if (_this._img) {
          var pt = _this._getPoint(e, true), s = _this._img.style;
          s.position = 'absolute';
          s.pointerEvents = 'none';
          s.zIndex = '999999';
          s.left = Math.round(pt.x - _this._imgOffset.x) + 'px';
          s.top = Math.round(pt.y - _this._imgOffset.y) + 'px';
        }
      });
    };
    // copy properties from an object to another
    DragDropTouch.prototype._copyProps = function (dst, src, props) {
      for (var i = 0; i < props.length; i++) {
        var p = props[i];
        dst[p] = src[p];
      }
    };
    DragDropTouch.prototype._copyStyle = function (src, dst) {
      // remove potentially troublesome attributes
      DragDropTouch._rmvAtts.forEach(function (att) {
        dst.removeAttribute(att);
      });
      // copy canvas content
      if (src instanceof HTMLCanvasElement) {
        var cSrc = src, cDst = dst;
        cDst.width = cSrc.width;
        cDst.height = cSrc.height;
        cDst.getContext('2d').drawImage(cSrc, 0, 0);
      }
      // copy style (without transitions)
      var cs = getComputedStyle(src);
      for (var i = 0; i < cs.length; i++) {
        var key = cs[i];
        if (key.indexOf('transition') < 0) {
          dst.style[key] = cs[key];
        }
      }
      dst.style.pointerEvents = 'none';
      // and repeat for all children
      for (var i = 0; i < src.children.length; i++) {
        this._copyStyle(src.children[i], dst.children[i]);
      }
    };
    DragDropTouch.prototype._dispatchEvent = function (e, type, target) {
      if (e && target) {
        var evt = document.createEvent('Event'), t = e.touches ? e.touches[0] : e;
        evt.initEvent(type, true, true);
        evt.button = 0;
        evt.which = evt.buttons = 1;
        this._copyProps(evt, e, DragDropTouch._kbdProps);
        this._copyProps(evt, t, DragDropTouch._ptProps);
        evt.dataTransfer = this._dataTransfer;
        target.dispatchEvent(evt);
        return evt.defaultPrevented;
      }
      return false;
    };
    // gets an element's closest draggable ancestor
    DragDropTouch.prototype._closestDraggable = function (e) {
      for (; e; e = e.parentElement) {
        if (e.hasAttribute('draggable') && e.draggable) {
          return e;
        }
      }
      return null;
    };
    return DragDropTouch;
  }());
  /*private*/
  DragDropTouch._instance = new DragDropTouch(); // singleton
  // constants
  DragDropTouch._THRESHOLD = 5; // pixels to move before drag starts
  DragDropTouch._OPACITY = 0.5; // drag image opacity
  DragDropTouch._DBLCLICK = 500; // max ms between clicks in a double click
  DragDropTouch._CTXMENU = 900; // ms to hold before raising 'contextmenu' event
  DragDropTouch._ISPRESSHOLDMODE = false; // decides of press & hold mode presence
  DragDropTouch._PRESSHOLDAWAIT = 400; // ms to wait before press & hold is detected
  DragDropTouch._PRESSHOLDMARGIN = 25; // pixels that finger might shiver while pressing
  DragDropTouch._PRESSHOLDTHRESHOLD = 0; // pixels to move before drag starts
  // copy styles/attributes from drag source to drag image element
  DragDropTouch._rmvAtts = 'id,class,style,draggable'.split(',');
  // synthesize and dispatch an event
  // returns true if the event has been handled (e.preventDefault == true)
  DragDropTouch._kbdProps = 'altKey,ctrlKey,metaKey,shiftKey'.split(',');
  DragDropTouch._ptProps = 'pageX,pageY,clientX,clientY,screenX,screenY'.split(',');
  DragDropTouch_1.DragDropTouch = DragDropTouch;
})(DragDropTouch || (DragDropTouch = {}));

window.pew.addRegistryEntry({key: 'OrdreComponent', domSelector: '[data-template="propositions-a-remettre-dans-lordre"]', classDef: OrdreComponent});
