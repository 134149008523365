import "./Accordion/AccordionComponent";
import "./ChapitreSlide/ChapitreSlideComponent";
import "./ChapitresSommaire/ChapitresSommaire";
import "./Cookie/CookieComponent";
import "./Dropdown/DropdownComponent";
import "./Form/FormComponent";
import "./FairePasfaire/FairePasFaireComponent";
import "./Header/HeaderComponent";
import "./Modal/ModalComponent";
import "./Notification/NotificationComponent";
import "./Ordre/OrdreComponent";
import "./ProgressCircle/jQuery-Plugin-SVG-Progress-Circle/progresscircle";
import "./ProgressCircle/vinci-circle-progress";
import "./QcmEnd/QcmEndComponent";
import "./Slider/SliderComponent";
import "./SliderInSlide/SliderInSlide"
import "./SlideVideo/SlideVideo"
import "./Tabs/TabsComponent";
import "./Video/VideoComponent";
