import {TimelineMax} from "gsap";

export class SlidePageAnim {
  out($oldContainer, passedOptions) {
    let defaultOptions = {paused: true};
    let options = $.extend(defaultOptions, passedOptions);
    let tl = new TimelineMax(options);

    tl.add(() => {
      $('html,body').animate({
        scrollTop: 0
      }, 500);
    });

    let $content = $oldContainer.find('.site-content');

    tl
      .to($content, .2, {scale: .95, ease: "power3.inOut"})
      .to($content, .4, {xPercent: '-100', autoAlpha: 0, ease: "power2.in"})
    ;

    return tl;
  }

  in($newContainer, passedOptions, $newPageRawHtmlContent) {
    let defaultOptions = {paused: true};
    let options = $.extend(defaultOptions, passedOptions);
    let tl = new TimelineMax(options);

    let $content = $newContainer.find('.site-content');

    tl
      .fromTo($content, .4, {xPercent: '100', autoAlpha: 0}, {xPercent: 0, autoAlpha: 1, ease: "power2.out"})
      .fromTo($content, .2, {scale: .95}, {scale: 1, ease: "power3.inOut"})
    ;
    tl.add(() => { this.croixFermetureTransition($newPageRawHtmlContent); });
    tl.add(() => { this.progressBarTransition($newPageRawHtmlContent); });

    return tl;
  }

  progressBarTransition($newPageRawHtmlContent) {
    let $newProgress = $newPageRawHtmlContent.find('.progress-bar-chapitres');
    let $currentProgress = $('body').find('.progress-bar-chapitres');

    if ($currentProgress.length) {
      let newProgressVal = $newProgress.find('#progress').val();
      let newProgressPercentage = $newProgress.find('.numbers .cursor').text();
      $currentProgress.find('#progress').val(newProgressVal);
      let $newCursor = $currentProgress.find('.numbers .cursor');
      $newCursor.text(newProgressPercentage);
      $newCursor.attr('style', 'left: calc(' + newProgressPercentage + ' - 10px);');
    } else {
      $newProgress.hide();
      $('#header').find('.direct-links').after($newProgress);
      $newProgress.fadeIn();
    }
  }

  croixFermetureTransition($newPageRawHtmlContent) {
    let $currentCroixFermeture = $('body').find('.direct-links .back-home');
    if(!$currentCroixFermeture.length){
      let $newCroixFermeture = $newPageRawHtmlContent.find('.direct-links .back-home');
      $newCroixFermeture.hide();
      $('#header').find('.direct-links').append($newCroixFermeture);
      $newCroixFermeture.fadeIn();
    }

  }
}
