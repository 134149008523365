import {TimelineMax} from "gsap";

export class ChapitresAnim {
  out($oldContainer, passedOptions) {
    let defaultOptions = {paused: true};
    let options = $.extend(defaultOptions, passedOptions);
    let tl = new TimelineMax(options);

    tl.add(() => {
      $('html,body').animate({
        scrollTop: 0
      }, 500);
    });

    let $chapitres = $oldContainer.find('.chapitres-sommaires li');
    tl
      .staggerTo($chapitres, .5, {yPercent: '-100', ease: "back.in"},.1)
      .to($oldContainer.find('.site-content'),.5,{backgroundColor:'#0B4792'},.3)
    ;

    return tl;
  }

  in($newContainer, passedOptions, $newPageRawHtmlContent) {
    let defaultOptions = {paused: true};
    let options = $.extend(defaultOptions, passedOptions);
    let tl = new TimelineMax(options);

    let $chapitres = $newContainer.find('.chapitres-sommaires li');
    tl.staggerFrom($chapitres, .5, {yPercent: '-100', ease: "back.in(.5)"},.05);

    return tl;
  }
}
