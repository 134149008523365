import {TimelineMax} from "gsap";

export class FinFormationAnim {
  in($newContainer, passedOptions, $newPageRawHtmlContent) {
    let defaultOptions = {paused: true};
    let options = $.extend(defaultOptions, passedOptions);
    let tl = new TimelineMax(options);

    tl.add(() => { this.progressBarTransition($newPageRawHtmlContent); });
    tl.fromTo($newContainer.find('.intro-chapitre'), .5, {autoAlpha: 0}, {autoAlpha: 1});

    return tl;
  }

  progressBarTransition($newPageRawHtmlContent) {
    let $newProgress = $newPageRawHtmlContent.find('.progress-bar-chapitres');
    let $currentProgress = $('body').find('.progress-bar-chapitres');

    if ($currentProgress.length) {
      let newProgressVal = $newProgress.find('#progress').val();
      let newProgressPercentage = $newProgress.find('.numbers .cursor').text();
      $currentProgress.find('#progress').val(newProgressVal);
      let $newCursor = $currentProgress.find('.numbers .cursor');
      $newCursor.text(newProgressPercentage);
      $newCursor.attr('style', 'left: calc(' + newProgressPercentage + ' - 10px);');
    } else {
      $newProgress.hide();
      $('#header').find('.direct-links').after($newProgress);
      $newProgress.fadeIn();
    }
  }
}
