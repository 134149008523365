import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export default class CircleChartComponent extends PewComponent {

  init(){
    this.element.circlechart();
  }

}

window.pew.addRegistryEntry({key: 'CircleChartComponent', domSelector: '.circlechart', classDef: CircleChartComponent});
