import "modaal/dist/js/modaal";
import {PewComponent} from "../../../assets/raw/js/components/pew-component";

// https://github.com/humaan/Modaal
const ModalOptions = {
  background: "#0A1F39",
  overlay_opacity: 1,
  fullscreen: false,
  close_text: "Fermer",
  after_open: function ($modal_wrapper) {
    window.pew.enhanceRegistry($modal_wrapper[0]);
  }
};

export class ModalComponent extends PewComponent {
  constructor(element) {
    if ($(element).data('modaal-type')) {
      ModalOptions['type'] = $(element).data('modaal-type');
    }
    super(element, ModalOptions);
  }

  init() {
    this.element.modaal(this.options);
  }
}

// Default
window.pew.addRegistryEntry({key: "wdf-modal", domSelector: ".wdf-modal", classDef: ModalComponent});
