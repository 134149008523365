import {PewComponent} from "../../../assets/raw/js/components/pew-component";

const Barba = require('Barba');

export default class ChapitreSlideComponent extends PewComponent {
  init() {
    setTimeout(() => {
      this.prefetchNextSlide();
    }, 1000);
  }

  prefetchNextSlide() {
    let $nextBtn = this.element.find('.next-step .btn');
    if ($nextBtn.length) {
      if ($nextBtn.hasClass('disabled')) {
        $nextBtn.on('unlock', () => {
          this.prefetchNextSlide();
          $nextBtn.off('unlock');
        });
      } else {
        let url = window.location.protocol + '//' + window.location.host + $nextBtn.attr('href');
        Barba.Utils.xhrTimeout = 8000;
        let xhr = Barba.Utils.xhr(url);
        Barba.Pjax.Cache.set(url, xhr);
        xhr
          .then((e) => {
            Barba.Utils.xhrTimeout = 5000;
          })
          .catch((e) => {
            console.error('Prefetch of next slide failed', e);
            Barba.Utils.xhrTimeout = 5000;
          });
      }
    }
  }
}
window.pew.addRegistryEntry({key: 'ContentComponent', domSelector: '[data-template="editeur-libre"]', classDef: ChapitreSlideComponent});
window.pew.addRegistryEntry({key: 'ExplicationComponent', domSelector: '[data-template="explication-decran"]', classDef: ChapitreSlideComponent});
