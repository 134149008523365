const Barba = require('Barba');
import * as transitions from "./transitions/index";
import {PewComponent} from "../components/pew-component";

export class AjaxComponent extends PewComponent {

  constructor(element, options) {
    super(element, options);
    this.clickedElement = null;
    this.newPageRawHtmlContent = null;
  }

  init() {
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
  }

  onLinkClicked(elt) {
    this.clickedElement = $(elt);
    if (this.clickedElement.data('transition')) {
      this.transitionName = this.clickedElement.data('transition');
    } else {
      this.transitionName = 'DefaultReplaceTransition';
    }
    this.clickedElement.addClass('barba-last-clicked');
    this.newPageRawHtmlContent = null;
    $('body').addClass('animating');
  }

  onNewPageReady(currentStatus, prevStatus, HTMLElementContainer, newPageRawHtmlContent) {
    this.newPageRawHtmlContent = newPageRawHtmlContent;
  }

  onTransitionCompleted(){
    if ("ontouchstart" in document.documentElement) {
      $('body').addClass('touch');
    } else {
      $('body').addClass('no-touch');
    }
    $('body').removeClass('animating');

    this.updatePageViewAnalytics();

    $(window).scroll();

    var EventManager = window.EventManager || $(document);
    EventManager.trigger('AjaxPage.onTransitionCompleted', {

    });
  }

  updateBodyClasses(newPageRawHtmlContent) {
    let bodyClasses = newPageRawHtmlContent.match(/body\sclass=['|"]([^'|"]*)['|"]/)[1];
    $('body').removeClass().addClass(bodyClasses + ' animating');
  }

  updatePageViewAnalytics() {
    if (window.ga) {
      ga('send', 'pageview');
    } else {
      //console.log('ga not ready');
    }
  }

}

const AjaxComponentInstance = new AjaxComponent($('#barba-wrapper'));
wonderwp.AjaxComponentInstance = AjaxComponentInstance;


/**
 * Barba configuration
 */
Barba.Dispatcher.on('linkClicked', (elt, e) => {
  AjaxComponentInstance.onLinkClicked(elt);
});

Barba.Dispatcher.on('newPageReady', (currentStatus, prevStatus, HTMLElementContainer, newPageRawHtmlContent) => {
  AjaxComponentInstance.onNewPageReady(currentStatus, prevStatus, HTMLElementContainer, newPageRawHtmlContent)
});

Barba.Pjax.getTransition = () => {
  let transitionClass = transitions[AjaxComponentInstance.transitionName] ? transitions[AjaxComponentInstance.transitionName] : transitions['default'];
  //console.log(transitionClass);
  return transitionClass;
};

Barba.Dispatcher.on('transitionCompleted', (currentStatus,prevStatus) => {
  AjaxComponentInstance.onTransitionCompleted();
});

Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

Barba.Pjax.preventCheck = function(evt, element) {
  if (!Barba.Pjax.originalPreventCheck(evt, element)) {
    return false;
  }

  if (element.classList.contains('bx-prev')) {
    return false;
  }
  if (element.classList.contains('bx-next')) {
    return false;
  }

  return true;
};
