import LockableComponent from "../Lockable/LockableComponent";
import {TimelineMax} from "gsap";

export default class FairePasFaireComponent extends LockableComponent {

  init() {
    this.btnChecker = this.element.find('button');
    super.init();
    this.lockCheckBtn();
    this.registerUnlockAnswerCheckTrigger();
    this.registerAnswerCheckTrigger();
  }

  lockCheckBtn() {
    this.lockBtn(this.btnChecker);
  }

  registerUnlockAnswerCheckTrigger() {
    this.element.find('input').on('change', () => {
      if (this.areAllAnswersCompleted()) {
        this.unlockBtn(this.btnChecker);
      }
    });
  }

  areAllAnswersCompleted() {
    let answersCount = this.element.find('input:checked').length;
    let questionsCount = this.element.find('.faire-item').length;
    return answersCount === questionsCount;
  }

  registerAnswerCheckTrigger() {
    this.btnChecker.on('click', () => {
      let checkCount = this.element.find('input:checked').length;
      if (checkCount > 0) {
        this.verifyAnswers();
        this.btnChecker.fadeOut();
      }
    });
  }

  verifyAnswers() {
    let $answerList = this.element.find('.faire-list .faire-item');
    let tl = new TimelineMax({
      paused: true,
      onComplete: this.unlock.bind(this)
    });
    $answerList.each((i, faireItem) => {
      let subTl = this.verifyAnswer($(faireItem), i);
      tl.add(subTl);
    });
    tl.play();
  }

  verifyAnswer($faireItem, index) {
    let allowed = $faireItem.data('allowed');
    let $answerInput = $faireItem.find('input[name=proposition_' + index + ']:checked');
    let answerInputText = $faireItem.find('input[value=' + allowed + ']').parent().find('label').text();
    let givenAnswer = parseInt($answerInput.val());
    let subTl = new TimelineMax();
    let isCorrect = (allowed === givenAnswer);
    let $choices = $faireItem.find('ul.choices');
    $choices.append('<li class="corrige"><label>' + answerInputText + '</label></li>');

    subTl.add(() => {
      let isMobile = window.innerWidth <= 768;
      if (isMobile) {
        let topPos = $faireItem.offset().top - 20;
        $('html,body').animate({
          scrollTop: topPos
        }, 100);
      } else {
        let topPos = $faireItem.position().top - 20;
        $('.site-content').animate({
          scrollTop: topPos
        }, 100);
      }
    });

    if (isCorrect) {
      subTl.to($faireItem, .1, {className: '+=faire-item success'});
    } else {
      subTl.to($faireItem, .1, {className: '+=faire-item error'});
      //subTl.fromTo($faireItem.find('.reponse'), 0.01, {x: -2}, {x: 2, clearProps: "x", repeat: 20});
    }

    /*let feedbackDelay = isCorrect ? .1 : .6;
    subTl
      .to($choices.find('.choice'), .2, {autoAlpha: 0, x: 5}, '+=' + feedbackDelay)
      .call(() => {$choices.find('.choice').remove();})
      .to($choices.find('.corrige'), .2, {autoAlpha: 1, x: 0})
    ;
    if (!isCorrect) {
      subTl.to($faireItem, .1, {className: '+=faire-item success'});
    }*/

    return subTl;
  }
}

window.pew.addRegistryEntry({key: 'FairePasFaireComponent', domSelector: '[data-template="a-faire-ne-pas-faire"]', classDef: FairePasFaireComponent});
