import {PewComponent} from "../../../assets/raw/js/components/pew-component";

const Barba = require('Barba');

export default class ChapitresSommaire extends PewComponent {
  init() {
    setTimeout(() => {
      this.prefetchNextSlide();
    }, 800);
  }

  prefetchNextSlide() {
    let $nextBtn = this.element.find('.in-progress .btn');
    if ($nextBtn.length) {
      let url = window.location.protocol + '//' + window.location.host + $nextBtn.attr('href');
      Barba.Utils.xhrTimeout = 8000;
      let xhr = Barba.Utils.xhr(url);
      Barba.Pjax.Cache.set(url, xhr);
      xhr
        .then((e)=>{
          Barba.Utils.xhrTimeout = 5000;
        })
        .catch((e) => {
          console.error('Prefetch of next slide failed', e);
          Barba.Utils.xhrTimeout = 5000;
        });
    }
  }
}
window.pew.addRegistryEntry({key: 'ChapitresSommaire', domSelector: '.chapitres-sommaires', classDef: ChapitresSommaire});
