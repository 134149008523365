import ChapitreSlideComponent from "../ChapitreSlide/ChapitreSlideComponent";

export default class LockableComponent extends ChapitreSlideComponent {

  init() {
    super.init();
    this.lock();
  }

  lock() {
    this.locked = true;
    let $btn = this.element.find('[data-next-step-link]');
    this.lockBtn($btn);
  }

  lockBtn($btn) {
    $btn.addClass('disabled').prop('disabled', true);
    $btn.on('click.lock', (e) => {
      e.preventDefault();
      return false;
    });
  }

  unlock() {
    this.locked = false;
    let $btn = this.element.find('[data-next-step-link]');
    this.unlockBtn($btn);
  }

  unlockBtn($btn) {
    $btn.removeClass('disabled').prop('disabled', false);
    $btn.off('click.lock');
    $btn.trigger('unlock');
  }

}
