const Barba = require('Barba');
import * as animations from "../animations";

export const DefaultReplaceTransition = Barba.BaseTransition.extend({
  start: function () {
    Promise
      .all([this.newContainerLoading, this.out()])
      .then(this.in.bind(this));
  },

  out: function () {
    let isMobile = window.innerWidth <= 768;
    let $oldContainer = $(this.oldContainer);

    if ($oldContainer.data('animation-out') && !isMobile) {
      let promise = $.Deferred();

      let animationName = $oldContainer.data('animation-out');
      let animationClass = '';

      try {
        animationClass = new animations[animationName]();
      } catch (e) {
        console.error("Animation [" + animationName + '] not found in ajax/animations index', e);
        return promise.reject();
      }

      let tl = animationClass.out($oldContainer, {
        onComplete: () => {
          promise.resolve();
        }
      });

      tl.play();

      return promise;

    } else {
      return $(this.oldContainer).animate({opacity: 0}, 300).promise();
    }

  },

  in: function () {
    let isMobile = window.innerWidth <= 768;
    let newPageRawHtmlContent = wonderwp.AjaxComponentInstance.newPageRawHtmlContent;
    wonderwp.AjaxComponentInstance.updateBodyClasses(newPageRawHtmlContent);

    window.pew.enhanceRegistry(this.newContainer);
    let $newContainer = $(this.newContainer);

    $newContainer.css({
      visibility: 'visible'
    });

    document.body.scrollTop = 0;
    $('html').scrollTop(0);

    if ($(this.oldContainer)) {
      $(this.oldContainer).hide();
    }

    if ($newContainer.data('animation-in') && !isMobile) {
      let animationName = $newContainer.data('animation-in');
      let animationClass = '';

      try {
        animationClass = new animations[animationName]();
        let tl = animationClass.in($newContainer, {
          onComplete: this.done.bind(this)
        }, $(newPageRawHtmlContent));

        tl.play();
      } catch (e) {
        console.error("Animation [" + animationName + '] not found in ajax/animations index', e);
        let $el = $(this.newContainer);
        $(this.oldContainer).hide();
        this.fadeContentIn($el);
      }

    } else {
      let $el = $(this.newContainer);
      $(this.oldContainer).hide();
      this.fadeContentIn($el);
    }

  },

  fadeContentIn: function ($el) {
    $el.css({
      visibility: 'visible',
      opacity: 0
    });
    $el.animate({opacity: 1}, 400, () => {
      this.done();
    });
  }

});
