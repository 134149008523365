export class AutoLogout {

  constructor(timeoutDuration, logoutUrl) {
    this.timeoutDuration = timeoutDuration;
    this.logoutUrl = logoutUrl;
    this.resetTimeOut();
    this.registerResetListener();
  }

  resetTimeOut() {
    //console.log('AutoLogout::resetTimeout');
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.handleTimeout.bind(this), this.timeoutDuration);
  }

  registerResetListener() {
    let EventManager = window.EventManager || $(document);
    EventManager.on('AjaxPage.onTransitionCompleted', (e, eventOpts) => {
      //console.log('AjaxPage.onTransitionCompleted', eventOpts);
      this.resetTimeOut();
    });
  }

  handleTimeout() {
    //console.log('AutoLogout::handleTimeout');
    if ($('body').hasClass('logged-in')) {
      window.location = this.logoutUrl;
    }
  }

}
