import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {TimelineMax} from "gsap";

export default class QcmEndComponent extends PewComponent {

  init() {
    this.registerReveal();
  }

  registerReveal() {
    let tl = new TimelineMax();
    let frags = this.element.find('.circle-chart__subline').text().split('/');
    let score = frags[0];
    let scale = frags[1];
    let startScore = {score: 0};
    let startColor = '#AAAAAA';

    tl.to(startScore, 2, {score: score, roundProps: "score", onUpdate: () => {this.element.find('.circle-chart__subline').text(startScore.score + '/' + scale);}});
    let endColor = this.element.find('.circlechart').hasClass('admis') ? '#17ADB5' : '#ED4370';

    tl
      .fromTo(this.element.find('circle'), 2, {stroke: startColor}, {stroke: endColor}, 0)
      .fromTo(this.element.find('.circle-chart__subline'), 2, {fill: startColor}, {fill: endColor}, 0);
    tl
      .fromTo(this.element.find('.contenttitle'), .5, {autoAlpha: 0, y: 10}, {autoAlpha: 1, y: 1}, '+=.4')
      .fromTo(this.element.find('.contenttext'), .4, {autoAlpha: 0, y: 10}, {autoAlpha: 1, y: 1}, '+=.8')
      .fromTo(this.element.find('.btn.no-barba'), .4, {autoAlpha: 0, y: 10}, {autoAlpha: 1, y: 1}, '-=.2')
    ;
  }

}

window.pew.addRegistryEntry({key: 'qcm-end-component', domSelector: '.qcm-end', classDef: QcmEndComponent});

