import LockableComponent from "../Lockable/LockableComponent";

export default class SliderInSlide extends LockableComponent {

  init() {
    super.init();
    this.registerSlider();
    this.registerHelpText();
  }

  registerSlider() {
    let numOfSlides = this.element.find('.slider-in-slide > *').length;
    this.options = {
      mode: 'fade',
      autoControlsCombine: true,
      keyboardEnabled: true,
      easing: 'ease-in-out',
      speed: 10,
      auto: false,
      autoControls: false,
      pager: false,
      stopAutoOnClick: true,
      infiniteLoop: false,
      adaptiveHeight: true,
      touchEnabled: false,
      onSliderLoad: (currentIndex) => {
        if (currentIndex === 0) {
          this.togglePrevBtn(false);
        }
      },
      onSlidePrev: ($slideElement, oldIndex, newIndex) => {
        if (newIndex === 0) {
          this.togglePrevBtn(false);
        }
        this.toggleNextBtn(true);
      },
      onSlideNext: ($slideElement, oldIndex, newIndex) => {
        if (newIndex + 1 === numOfSlides) {
          this.unlock();
          this.toggleNextBtn(false);
        }
        this.togglePrevBtn(true);
      }
    };
    this.slider = this.element.find('.slider-in-slide').bxSlider(this.options);
    $(document).on('ready', () => {
      this.reloadSlider('domready');
    });
    setTimeout(() => {
      this.reloadSlider('timeout');
    }, 1000);
  }

  reloadSlider(origin) {
    this.slider.reloadSlider(this.options);
  }

  togglePrevBtn(show) {
    this.toggleNavBtn(this.element.find('.bx-prev'), show)
  }

  toggleNextBtn(show) {
    this.toggleNavBtn(this.element.find('.bx-next'), show)
  }

  toggleNavBtn($btn, show) {
    if (show) {
      $btn.fadeIn();
    } else {
      $btn.fadeOut();
    }
  }

  registerHelpText() {
    const $btn = this.element.find('.next-step');
    $btn.hover(
      (e) => {
        if ($btn.find('.btn').hasClass('disabled')) {
          this.element.find('.slider-alert').addClass('revealed');
        }
      },
      () => {
        if ($btn.find('.btn').hasClass('disabled')) {
          this.element.find('.slider-alert').removeClass('revealed');
        }
      }
    );
  }

}
window.pew.addRegistryEntry({key: 'slider-in-slide', domSelector: '[data-template="slider"]', classDef: SliderInSlide});
