import LockableComponent from "../Lockable/LockableComponent";
import {VideoComponent} from "../Video/VideoComponent";

export default class SlideVideo extends LockableComponent {

  init() {
    super.init();
    this.registerTrigger();
  }

  registerTrigger() {
    let isMobile = window.innerWidth <= 768;
    if(isMobile){
      this.element.find('.video-trigger').hide();
      let $video = this.element.find('.video');
      $video.prop('controls',true);
      this.registerUnlockAfterVideo($video, null);
      $video.on('ended', function(){
        $video[0].webkitExitFullscreen();
      });
    } else {
      this.element.find('.video-trigger').modaal({
        background: "#0A1F39",
        overlay_opacity: 1,
        fullscreen: false,
        close_text: "Fermer",
        after_open: ($modal_wrapper) => {
          new VideoComponent($modal_wrapper.find('.video-player'));
          $modal_wrapper.find('.controls').attr('data-state', 'visible');
          let $vid = $modal_wrapper.find('.video');
          $modal_wrapper.find('.playpause').click();
          this.registerUnlockAfterVideo($vid, $modal_wrapper);
        },
        after_close: () => {
          this.element.find('.controls').removeAttr('data-state');
        }
      });
    }
  }

  registerUnlockAfterVideo($vid,$modal_wrapper) {
    $vid.on('ended', () => {
      this.unlock();
      if($modal_wrapper) {
        $modal_wrapper.find('.modaal-close').click();
      }
    });
  }

}
window.pew.addRegistryEntry({key: 'video-in-slide', domSelector: '[data-template="video"]', classDef: SlideVideo});
